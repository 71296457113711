var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.editMode
      ? _c(
          "div",
          [
            _c("g-title", [_vm._v("作品系数")]),
            _c(
              "info-item",
              {
                staticClass: "margin-bottom-0",
                attrs: {
                  "label-width": _vm.labelWidth,
                  label: "文本量 系数占比",
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.formData.data.productionCoefficient.textCountRate
                  ) + " %"
                ),
              ]
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("一话文本量")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("系数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.highTextCount
                            .count
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.formData.data.productionCoefficient.highTextCount
                          .coefficient
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient
                            .mediumTextCount.count
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.formData.data.productionCoefficient.mediumTextCount
                          .coefficient
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.lowTextCount
                            .count
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.formData.data.productionCoefficient.lowTextCount
                          .coefficient
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "info-item",
              {
                staticClass: "margin-bottom-0",
                attrs: {
                  "label-width": _vm.labelWidth,
                  label: "对白长度 系数占比",
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.formData.data.productionCoefficient.dialogLengthRate
                  ) + " %"
                ),
              ]
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("对白长度")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("系数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient
                            .highDialogLength.count
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.formData.data.productionCoefficient.highDialogLength
                          .coefficient
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient
                            .mediumDialogLength.count
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.formData.data.productionCoefficient
                          .mediumDialogLength.coefficient
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient
                            .lowDialogLength.count
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.formData.data.productionCoefficient.lowDialogLength
                          .coefficient
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "info-item",
              {
                staticClass: "margin-bottom-0",
                attrs: {
                  "label-width": _vm.labelWidth,
                  label: "题材难度 系数占比",
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.formData.data.productionCoefficient.subjectRate) +
                    " %"
                ),
              ]
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("低")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.hardSubject
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.normalSubject
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.easySubject
                        )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "info-item",
              {
                staticClass: "margin-bottom-0",
                attrs: {
                  "label-width": _vm.labelWidth,
                  label: "平台难度 系数占比",
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.formData.data.productionCoefficient.platformRate) +
                    " %"
                ),
              ]
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("低")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.hardPlatform
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.normalPlatform
                        )
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formData.data.productionCoefficient.easyPlatform
                        )
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c("g-title", [_vm._v("文字系数")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("原文字数")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("系数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " + _vm._s(_vm.formData.data.highWordCount.count)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.highWordCount.coefficient)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-1", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      "> " + _vm._s(_vm.formData.data.mediumWordCount.count)
                    ),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.formData.data.mediumWordCount.coefficient)
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v("> " + _vm._s(_vm.formData.data.lowWordCount.count)),
                  ]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.formData.data.lowWordCount.coefficient)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("g-title", [_vm._v("作品系数")]),
            _c(
              "a-form-model-item",
              {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { label: "文本量 系数占比" },
              },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入文本量的占比", suffix: "%" },
                  model: {
                    value:
                      _vm.formData.data.productionCoefficient.textCountRate,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData.data.productionCoefficient,
                        "textCountRate",
                        $$v
                      )
                    },
                    expression:
                      "formData.data.productionCoefficient.textCountRate",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("一话文本量")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("系数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入一话文本量" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.highTextCount
                            .count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .highTextCount,
                            "count",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.highTextCount.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.highTextCount
                            .coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .highTextCount,
                            "coefficient",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.highTextCount.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入一话文本量" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .mediumTextCount.count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .mediumTextCount,
                            "count",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.mediumTextCount.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .mediumTextCount.coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .mediumTextCount,
                            "coefficient",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.mediumTextCount.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入一话文本量" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.lowTextCount
                            .count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .lowTextCount,
                            "count",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.lowTextCount.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.lowTextCount
                            .coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .lowTextCount,
                            "coefficient",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.lowTextCount.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "a-form-model-item",
              {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { label: "对白长度  系数占比" },
              },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入对白长度的占比", suffix: "%" },
                  model: {
                    value:
                      _vm.formData.data.productionCoefficient.dialogLengthRate,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData.data.productionCoefficient,
                        "dialogLengthRate",
                        $$v
                      )
                    },
                    expression:
                      "formData.data.productionCoefficient.dialogLengthRate",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("对白长度")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("系数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入对白长度" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .highDialogLength.count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .highDialogLength,
                            "count",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.highDialogLength.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .highDialogLength.coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .highDialogLength,
                            "coefficient",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.highDialogLength.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入对白长度" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .mediumDialogLength.count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .mediumDialogLength,
                            "count",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.mediumDialogLength.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .mediumDialogLength.coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .mediumDialogLength,
                            "coefficient",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.mediumDialogLength.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入对白长度" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .lowDialogLength.count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .lowDialogLength,
                            "count",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.lowDialogLength.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .lowDialogLength.coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient
                              .lowDialogLength,
                            "coefficient",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.lowDialogLength.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "a-form-model-item",
              {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { label: "题材难度  系数占比" },
              },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入题材难度占比", suffix: "%" },
                  model: {
                    value: _vm.formData.data.productionCoefficient.subjectRate,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData.data.productionCoefficient,
                        "subjectRate",
                        $$v
                      )
                    },
                    expression:
                      "formData.data.productionCoefficient.subjectRate",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("低")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.hardSubject,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient,
                            "hardSubject",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.hardSubject",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.normalSubject,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient,
                            "normalSubject",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.normalSubject",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.easySubject,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient,
                            "easySubject",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.easySubject",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "a-form-model-item",
              {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { label: "平台难度  系数占比" },
              },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入平台难度占比", suffix: "%" },
                  model: {
                    value: _vm.formData.data.productionCoefficient.platformRate,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData.data.productionCoefficient,
                        "platformRate",
                        $$v
                      )
                    },
                    expression:
                      "formData.data.productionCoefficient.platformRate",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("高")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("中")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("低")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.hardPlatform,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient,
                            "hardPlatform",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.hardPlatform",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient
                            .normalPlatform,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient,
                            "normalPlatform",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.normalPlatform",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入占比" },
                      model: {
                        value:
                          _vm.formData.data.productionCoefficient.easyPlatform,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.productionCoefficient,
                            "easyPlatform",
                            $$v
                          )
                        },
                        expression:
                          "formData.data.productionCoefficient.easyPlatform",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("g-title", [_vm._v("文字系数")]),
            _c(
              "a-row",
              { attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("等级")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("原文字数")]),
                ]),
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "py-3" }, [_vm._v("系数")]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("高")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入原文字数" },
                      model: {
                        value: _vm.formData.data.highWordCount.count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.highWordCount,
                            "count",
                            $$v
                          )
                        },
                        expression: "formData.data.highWordCount.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入系数" },
                      model: {
                        value: _vm.formData.data.highWordCount.coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.highWordCount,
                            "coefficient",
                            $$v
                          )
                        },
                        expression: "formData.data.highWordCount.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("中")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入原文字数" },
                      model: {
                        value: _vm.formData.data.mediumWordCount.count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumWordCount,
                            "count",
                            $$v
                          )
                        },
                        expression: "formData.data.mediumWordCount.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入系数" },
                      model: {
                        value: _vm.formData.data.mediumWordCount.coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.mediumWordCount,
                            "coefficient",
                            $$v
                          )
                        },
                        expression: "formData.data.mediumWordCount.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-6", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _c("label", [_vm._v("低")]),
                ]),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { prefix: ">", placeholder: "请输入原文字数" },
                      model: {
                        value: _vm.formData.data.lowWordCount.count,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.data.lowWordCount, "count", $$v)
                        },
                        expression: "formData.data.lowWordCount.count",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入系数" },
                      model: {
                        value: _vm.formData.data.lowWordCount.coefficient,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData.data.lowWordCount,
                            "coefficient",
                            $$v
                          )
                        },
                        expression: "formData.data.lowWordCount.coefficient",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }