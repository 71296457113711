<template>
  <div>
    <div v-if="!editMode">
      <g-title>作品系数</g-title>
      <info-item class="margin-bottom-0" :label-width="labelWidth" label="文本量 系数占比"
        >{{ formData.data.productionCoefficient.textCountRate }} %</info-item
      >
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">一话文本量</div> </a-col>
        <a-col :span="6"> <div class="py-3">系数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.productionCoefficient.highTextCount.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.productionCoefficient.highTextCount.coefficient }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.productionCoefficient.mediumTextCount.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.productionCoefficient.mediumTextCount.coefficient }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.productionCoefficient.lowTextCount.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.productionCoefficient.lowTextCount.coefficient }}</label>
        </a-col>
      </a-row>
      <a-divider />

      <info-item class="margin-bottom-0" :label-width="labelWidth" label="对白长度 系数占比"
        >{{ formData.data.productionCoefficient.dialogLengthRate }} %</info-item
      >
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">对白长度</div> </a-col>
        <a-col :span="6"> <div class="py-3">系数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.productionCoefficient.highDialogLength.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.productionCoefficient.highDialogLength.coefficient }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.productionCoefficient.mediumDialogLength.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.productionCoefficient.mediumDialogLength.coefficient }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.productionCoefficient.lowDialogLength.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.productionCoefficient.lowDialogLength.coefficient }}</label>
        </a-col>
      </a-row>
      <a-divider />

      <info-item class="margin-bottom-0" :label-width="labelWidth" label="题材难度 系数占比"
        >{{ formData.data.productionCoefficient.subjectRate }} %</info-item
      >
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">高</div> </a-col>
        <a-col :span="6"> <div class="py-3">中</div> </a-col>
        <a-col :span="6"> <div class="py-3">低</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label> {{ formData.data.productionCoefficient.hardSubject }}</label>
        </a-col>
        <a-col :span="6">
          <label> {{ formData.data.productionCoefficient.normalSubject }}</label>
        </a-col>
        <a-col :span="6">
          <label> {{ formData.data.productionCoefficient.easySubject }}</label>
        </a-col>
      </a-row>
      <a-divider />

      <info-item class="margin-bottom-0" :label-width="labelWidth" label="平台难度 系数占比" 
        >{{ formData.data.productionCoefficient.platformRate }} %</info-item
      >
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">高</div> </a-col>
        <a-col :span="6"> <div class="py-3">中</div> </a-col>
        <a-col :span="6"> <div class="py-3">低</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label> {{ formData.data.productionCoefficient.hardPlatform }}</label>
        </a-col>
        <a-col :span="6">
          <label> {{ formData.data.productionCoefficient.normalPlatform }}</label>
        </a-col>
        <a-col :span="6">
          <label> {{ formData.data.productionCoefficient.easyPlatform }}</label>
        </a-col>
      </a-row>

      <g-title>文字系数</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">原文字数</div></a-col>
        <a-col :span="6"> <div class="py-3">系数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.highWordCount.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.highWordCount.coefficient }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-1">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.mediumWordCount.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.mediumWordCount.coefficient }}</label>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <label>> {{ formData.data.lowWordCount.count }}</label>
        </a-col>
        <a-col :span="6">
          <label>{{ formData.data.lowWordCount.coefficient }}</label>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <g-title>作品系数</g-title>
      <a-form-model-item label="文本量 系数占比" style="margin-bottom: 0px">
        <a-input
          v-model="formData.data.productionCoefficient.textCountRate"
          placeholder="请输入文本量的占比"
          suffix="%"
        />
      </a-form-model-item>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">一话文本量</div> </a-col>
        <a-col :span="6"> <div class="py-3">系数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix=">"
            v-model="formData.data.productionCoefficient.highTextCount.count"
            placeholder="请输入一话文本量"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.highTextCount.coefficient" placeholder="请输入占比" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix=">"
            v-model="formData.data.productionCoefficient.mediumTextCount.count"
            placeholder="请输入一话文本量"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.mediumTextCount.coefficient" placeholder="请输入占比" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix=">"
            v-model="formData.data.productionCoefficient.lowTextCount.count"
            placeholder="请输入一话文本量"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.lowTextCount.coefficient" placeholder="请输入占比" />
        </a-col>
      </a-row>
      <a-divider />

      <a-form-model-item label="对白长度  系数占比" style="margin-bottom: 0px">
        <a-input
          v-model="formData.data.productionCoefficient.dialogLengthRate"
          placeholder="请输入对白长度的占比"
          suffix="%"
        />
      </a-form-model-item>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">对白长度</div> </a-col>
        <a-col :span="6"> <div class="py-3">系数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix=">"
            v-model="formData.data.productionCoefficient.highDialogLength.count"
            placeholder="请输入对白长度"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            v-model="formData.data.productionCoefficient.highDialogLength.coefficient"
            placeholder="请输入占比"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix=">"
            v-model="formData.data.productionCoefficient.mediumDialogLength.count"
            placeholder="请输入对白长度"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            v-model="formData.data.productionCoefficient.mediumDialogLength.coefficient"
            placeholder="请输入占比"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <a-input
            prefix=">"
            v-model="formData.data.productionCoefficient.lowDialogLength.count"
            placeholder="请输入对白长度"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.lowDialogLength.coefficient" placeholder="请输入占比" />
        </a-col>
      </a-row>
      <a-divider />

      <a-form-model-item label="题材难度  系数占比" style="margin-bottom: 0px">
        <a-input
          v-model="formData.data.productionCoefficient.subjectRate"
          placeholder="请输入题材难度占比"
          suffix="%"
        />
      </a-form-model-item>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">高</div> </a-col>
        <a-col :span="6"> <div class="py-3">中</div> </a-col>
        <a-col :span="6"> <div class="py-3">低</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.hardSubject" placeholder="请输入占比" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.normalSubject" placeholder="请输入占比" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.easySubject" placeholder="请输入占比" />
        </a-col>
      </a-row>
      <a-divider />

      <a-form-model-item label="平台难度  系数占比" style="margin-bottom: 0px">
        <a-input
          v-model="formData.data.productionCoefficient.platformRate"
          placeholder="请输入平台难度占比"
          suffix="%"
        />
      </a-form-model-item>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">高</div> </a-col>
        <a-col :span="6"> <div class="py-3">中</div> </a-col>
        <a-col :span="6"> <div class="py-3">低</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.hardPlatform" placeholder="请输入占比" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.normalPlatform" placeholder="请输入占比" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.productionCoefficient.easyPlatform" placeholder="请输入占比" />
        </a-col>
      </a-row>

      <g-title>文字系数</g-title>
      <a-row :gutter="16">
        <a-col :span="6"> <div class="py-3">等级</div> </a-col>
        <a-col :span="6"> <div class="py-3">原文字数</div></a-col>
        <a-col :span="6"> <div class="py-3">系数</div> </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>高</label>
        </a-col>
        <a-col :span="6">
          <a-input prefix=">" v-model="formData.data.highWordCount.count" placeholder="请输入原文字数" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.highWordCount.coefficient" placeholder="请输入系数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>中</label>
        </a-col>
        <a-col :span="6">
          <a-input prefix=">" v-model="formData.data.mediumWordCount.count" placeholder="请输入原文字数" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.mediumWordCount.coefficient" placeholder="请输入系数" />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-6">
        <a-col :span="6">
          <label>低</label>
        </a-col>
        <a-col :span="6">
          <a-input prefix=">" v-model="formData.data.lowWordCount.count" placeholder="请输入原文字数" />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.data.lowWordCount.coefficient" placeholder="请输入系数" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    propsFormData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      labelWidth: '140',
      layout: {
        labelCol: {
          style: 'width: 140px',
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      },
      formData: this.propsFormData,
    }
  },
}
</script>

<style scoped>
label {
  line-height: 30px;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.py-3 {
  font-weight: bold;
  color: #333;
}
</style>